<style scoped>
.listExercices {
  height: 550px;
  overflow: scroll;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/functions";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Exercices",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllExercices();
  },
  data() {
    return {
      title: "Exercices",
      exercices: [],
      exercice: null,
      adding_exercice: false,
      saving_photo: false,
      saving_modification: false,
      saving_video_horizontale: false,
      saving_video_verticale: false,
      transloading_video_horizontale: false,
      transloading_video_verticale: false,
      transloading_photo: false,
      forceRegenerateMedia: 1,
      uploadValue: 0,
      muscles: [
        "Legs",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
        "Stretching",
        "Warming up"
      ],
      niveaux: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      langues: ["it", "fr", "us", "es", "br"],
      items: [
        {
          text: "Exercices",
        },
        {
          text: "exercices",
          active: true,
        },
      ],
    };
  },
  methods: {
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.exercice.data.photo.original;

      var data = {
        id: this.exercice.id,
        url: url,
        type: "exercices",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.exercice.data.photo = result.data.photo;
        firebase
          .firestore()
          .collection("exercices")
          .doc(data.id)
          .update(this.exercice.data);
        this.transloading_photo = false;
      });
    },
    async transloadVideo(noeud) {
      if (noeud == "video_horizontale")
        this.transloading_video_horizontale = true;
      if (noeud == "video_verticale") this.transloading_video_verticale = true;

      var video = this.exercice.data[noeud].original;

      var data = {
        id: this.exercice.id,
        url: video,
        type: "exercices",
        noeud: noeud,
      };

      var transloadVideo = firebase.functions().httpsCallable("transloadVideo");
      transloadVideo(data).then((result) => {
        // Read result of the Cloud Function.
        this.exercice.data[noeud] = result.data[noeud];

        firebase
          .firestore()
          .collection("exercices")
          .doc(data.id)
          .update(this.exercice.data);
        if (noeud == "video_horizontale")
          this.transloading_video_horizontale = false;
        if (noeud == "video_verticale")
          this.transloading_video_verticale = false;
      });
    },
    async transferVideoGoogle(idexercices, chemin, id, index) {
      if (index == "video_horizontale") this.saving_video_horizontale = true;
      if (index == "video_verticale") this.saving_video_verticale = true;

      var newPhoto = document.getElementById(id).files[0];
      var type = newPhoto.name.split(".").pop();
      var filename = chemin + "/" + idexercices + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          if (index == "video_horizontale")
            this.uploadValueVideoHorizontale =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (index == "video_verticale")
            this.uploadValueVideoVerticale =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("exercices")
              .doc(this.exercice.id)
              .update({
                [index]: {
                  original: url,
                  mobile: null,
                },
              })
              .then(() => {
                this.forceRegenerateMedia++;
                this.exercice.data[index] = {
                  original: url,
                  mobile: null,
                };
                if (index == "video_horizontale")
                  this.saving_video_horizontale = false;
                if (index == "video_verticale")
                  this.saving_video_verticale = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    async transferPhotoGoogle(idexercices, chemin, id, index) {
      this.saving_photo = true;

      var newPhoto = document.getElementById(id).files[0];
      var type = newPhoto.name.split(".").pop();
      var filename = chemin + "/" + idexercices + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            console.log("ingg: " + this.exercice.id);
            var db = firebase.firestore();
            db.collection("exercices")
              .doc(this.exercice.id)
              .update({
                [index]: {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                },
              })
              .then(() => {
                this.forceRegenerateMedia++;
                this.exercice.data[index] = {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                };
                this.saving_photo = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    saveModificationsExercice() {
      //On recupère toutes les valeurs

      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("exercices")
        .doc(this.exercice.id)
        .update(this.exercice.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewExercice() {
      this.adding_exercice = true;

      var db = firebase.firestore();
      db.collection("exercices")
        .doc(this.exercice.id)
        .set(this.exercice.data)
        .then(() => {
          this.adding_exercice = false;
          this.add_exercice = false;
          this.exercices = [];
          this.exercice = null;
          this.getAllExercices();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhotoExercice(exercice) {
      if (exercice.data.photo.original != null)
        return exercice.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoExerciceThumb(exercice) {
      if (exercice.data.photo.thumbnail != null)
        return exercice.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    searchExercice() {
      var search = document
        .getElementById("searchexercice")
        .value.toLowerCase();
      var muscle = document.getElementById("searchMuscle").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='exercice']").forEach((element) => {
        var id_exercice = element.dataset.id;
        let exercice = this.exercices.find((el) => el.id === id_exercice);
        if (
          (exercice.data.description.it.nom.toLowerCase().includes(search) ||
            exercice.data.description.fr.nom.toLowerCase().includes(search) ||
            exercice.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (exercice.data.muscles.includes(muscle) || muscle == "all")
        )
          element.style = "display: block";
        else element.style = "display: none";
      });
    },
    getAllExercices() {
      var db = firebase.firestore();
      db.collection("exercices")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.exercices.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    setExercice(exercice) {
      this.exercice = exercice;
    },
    showAddExercice() {
      var json_exercice = {
        photo: {
          original: null,
          optimized: null,
          thumbnail: null
        },
        video_horizontale: {
          original: null,
          mobile: null
        },
        video_verticale: {
          original: null,
          mobile: null
        },
        niveaux: [],
        muscles: [],
        materiels: [],
        difficulte: 5,
        unilateral: false,
        description: {
          it: {
            nom: "",
            description: "",
            instructions: [],
          },
          fr: {
            nom: "",
            description: "",
            instructions: [],
          },
          us: {
            nom: "",
            description: "",
            instructions: [],
          },
          es: {
            nom: "",
            description: "",
            instructions: [],
          },
          pt: {
            nom: "",
            description: "",
            instructions: [],
          },
        },
      };
      this.exercice = {
        type: "new",
        id: String(Date.now()),
        data: json_exercice,
      };
    },
    AddInsctruction(key) {
      this.exercice.data.description[key].instructions.push("");
    },
    deleteInscrution(key, index) {
      this.exercice.data.description[key].instructions.splice(index, 1);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddExercice()"
              >
                + Add exercice
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchexercice"
              placeholder="Search exercice..."
              @keyup="searchExercice()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchExercice()"
              id="searchMuscle"
            >
              <option value="all" selected>All muscles</option>
              <option
                v-for="muscle in muscles"
                :key="'musclesearch' + muscle"
                :value="muscle"
              >
                {{ muscle }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ exercices.length }} exercices</small>
            </div>
            <div class="listExercices">
              <div
                v-for="(exercice, key) in exercices"
                :key="'exercice' + key"
                :id="'exerciceid' + key"
                :data-id="exercice.id"
                data-type="exercice"
                @click="setExercice(exercice)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-md-auto">
                      <img
                        width="60px"
                        :src="getPhotoExerciceThumb(exercice)"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ exercice.data.description.it.nom }}<br/>
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in exercice.data
                            .description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              exercice.data.description[name].nom == '' ||
                              exercice.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="exercice == null" class="text-center">
              Select exercice
            </div>
            <div v-if="exercice != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-2">
                    <img
                      width="100%"
                      :src="getPhotoExercice(exercice)"
                      :key="'photoexercice_' + exercice.id"
                      alt
                      class="img-thumbnail"
                    />
                    <ul>
                      <li>
                        <small
                          >Original:
                          <a
                            :href="exercice.data.photo.original"
                            target="_blank"
                            >{{ exercice.data.photo.original }}</a
                          ></small
                        >
                      </li>
                      <li>
                        <small
                          >Optimized:
                          <a
                            :href="exercice.data.photo.optimized"
                            target="_blank"
                            >{{ exercice.data.photo.optimized }}</a
                          ></small
                        >
                      </li>
                      <li>
                        <small
                          >Thumbnail:
                          <a
                            :href="exercice.data.photo.thumbnail"
                            target="_blank"
                            >{{ exercice.data.photo.thumbnail }}</a
                          ></small
                        >
                      </li>
                    </ul>
                    <div
                      class="d-grid gap-2 mt-2 mb-2"
                      v-if="exercice.type != 'new'"
                    >
                      <label for="formFile" class="form-label"
                        >Change photo:</label
                      >
                      <input class="form-control" type="file" id="newPhoto" />
                      <progress
                        v-if="saving_photo"
                        id="progress"
                        :value="uploadValue"
                        max="100"
                      ></progress>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @click="
                          transferPhotoGoogle(
                            exercice.id,
                            'exercices',
                            'newPhoto',
                            'photo'
                          )
                        "
                      >
                        <span
                          v-if="saving_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update photo
                      </button>
                    </div>
                    <div
                      v-if="exercice.data.photo.original != null"
                      class="d-grid gap-2 mt-2"
                    >
                      <button
                        class="btn btn-info btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @dblclick="transloadPhoto()"
                      >
                        <span
                          v-if="transloading_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Transload photo
                      </button>
                    </div>
                    <hr />
                    <div v-if="exercice.type == 'new'">
                      Video & Photo will update after creation.
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ exercice.data.description.it.nom }}
                          </h1>
                          <small>Exercice #{{ exercice.id }}</small>
                          
                        </div>
                        <div class="col-4">
                          <button
                            v-if="exercice.type != 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="saveModificationsExercice()"
                          >
                            <span
                              v-if="saving_modification"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Save modifications
                          </button>
                          <button
                            v-if="exercice.type == 'new'"
                            type="button"
                            class="btn btn-success btn-lg float-end"
                            @click="addNewExercice()"
                          >
                            <span
                              v-if="adding_exercice"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Add exercice
                          </button>
                        </div>
                      </div>

                      <hr />
                      <h3>Video Horizontale:</h3>
                      <ul>
                        <li>
                          Originale:
                          <a
                            :href="exercice.data.video_horizontale.original"
                            target="_blank"
                            >{{ exercice.data.video_horizontale.original }}</a
                          >
                        </li>
                        <li>
                          Mobile:
                          <a
                            :href="exercice.data.video_horizontale.mobile"
                            target="_blank"
                            >{{ exercice.data.video_horizontale.mobile }}</a
                          >
                        </li>

                      </ul>

                      <div
                        class="d-grid gap-2 mt-2 mb-2"
                        v-if="exercice.type != 'new'"
                      >
                        <button
                          class="btn btn-info btn-sm"
                          type="button"
                          @dblclick="transloadVideo('video_horizontale')"
                        >
                          <span
                            v-if="transloading_video_horizontale"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Transload Video
                        </button>
                        <label for="formFile" class="form-label"
                          >Change vidéo horizontale:</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="videohorizontale"
                        />
                        <progress
                          v-if="saving_video_horizontale"
                          id="progress2"
                          :value="uploadValueVideoHorizontale"
                          max="100"
                        ></progress>
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="uploadVideoHorizontale"
                          @click="
                            transferVideoGoogle(
                              exercice.id,
                              'exercices',
                              'videohorizontale',
                              'video_horizontale'
                            )
                          "
                        >
                          <span
                            v-if="saving_video_horizontale"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Update video
                        </button>
                      </div>
                      <hr />
                      <h3>Video Verticale:</h3>
                      <ul>
                        <li>
                          Originale:
                          <a
                            :href="exercice.data.video_verticale.original"
                            target="_blank"
                            >{{ exercice.data.video_verticale.original }}</a
                          >
                        </li>
                        <li>
                          Mobile:
                          <a
                            :href="exercice.data.video_verticale.mobile"
                            target="_blank"
                            >{{ exercice.data.video_verticale.mobile }}</a
                          >
                        </li>
                      </ul>
                      <div
                        class="d-grid gap-2 mt-2"
                        v-if="exercice.type != 'new'"
                      >
                        <button
                          class="btn btn-info btn-sm"
                          type="button"
                          @dblclick="transloadVideo('video_verticale')"
                        >
                          <span
                            v-if="transloading_video_verticale"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Transload Video
                        </button>
                        <label for="formFile" class="form-label"
                          >Change vidéo verticale:</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="videoverticale"
                        />
                        <progress
                          v-if="saving_video_verticale"
                          id="progress2"
                          :value="uploadValueVideoVerticale"
                          max="100"
                        ></progress>
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="uploadVideoVerticale"
                          @click="
                            transferVideoGoogle(
                              exercice.id,
                              'exercices',
                              'videoverticale',
                              'video_verticale'
                            )
                          "
                        >
                          <span
                            v-if="saving_video_verticale"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Update video
                        </button>
                      </div>
                      <hr />

                      <div class="container px-0 py-2">
                        <div class="row">
                          <b-tabs content-class="mt-3">
                            <b-tab
                              v-for="(langue, key) in exercice.data.description"
                              :key="'descriptiontab-' + key"
                              :active="key === 'it'"
                            >
                              <template slot="title">
                                <img
                                  :src="
                                    'https://flagcdn.com/h20/' + key + '.png'
                                  "
                                  height="12"
                                />
                                {{ key }}
                              </template>
                              <div class="mb-3">
                                <label for="add-nom_it" class="form-label"
                                  >Name*</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="'nom_' + key"
                                  v-model="exercice.data.description[key].nom"
                                />
                              </div>
                              <div class="mb-3">
                                <label
                                  :for="'description_' + key"
                                  class="form-label"
                                  >Description*</label
                                >
                                <textarea
                                  class="form-control"
                                  :id="'description_' + key"
                                  rows="3"
                                  v-model="
                                    exercice.data.description[key].description
                                  "
                                ></textarea>
                              </div>
                              <div class="mb-3">
                                <label
                                  :for="'description_' + key"
                                  class="form-label"
                                  >Instructions</label
                                >
                                <div
                                  v-if="
                                    exercice.data.description[key].instructions
                                      .length == 0
                                  "
                                  class="alert alert-danger"
                                >
                                  Aucune instruction pour le moment.
                                </div>
                                <div
                                  v-if="
                                    exercice.data.description[key].instructions
                                      .length > 0
                                  "
                                  class="alert alert-info"
                                >
                                  <i class="fas fa-info-circle"></i> Double
                                  click to delete an instruction.
                                </div>
                                <div
                                  v-for="(instruction, index) in exercice.data
                                    .description[key].instructions"
                                  :key="'instruction_' + key + index"
                                  class="mt-4"
                                >
                                  <div class="position-relative">
                                    <div
                                      class="
                                        position-absolute
                                        top-0
                                        start-0
                                        translate-middle
                                        badge
                                        rounded-pill
                                        bg-info
                                      "
                                    >
                                      {{ index + 1 }}
                                    </div>
                                    <div
                                      class="
                                        position-absolute
                                        top-0
                                        start-100
                                        translate-middle
                                      "
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-danger btn-sm"
                                        @dblclick="deleteInscrution(key, index)"
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </div>
                                    <textarea
                                      class="form-control"
                                      :id="'description_' + key"
                                      rows="3"
                                      v-model="
                                        exercice.data.description[key]
                                          .instructions[index]
                                      "
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="d-grid gap-2">
                                  <button
                                    type="button"
                                    class="btn btn-dark mt-3"
                                    @click="AddInsctruction(key)"
                                  >
                                    + Add instruction
                                  </button>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="unilaterale"
                            :key="'unilaterale_' + exercice.id"
                            v-model="exercice.data.unilateral"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Unilaterale?
                          </label>
                        </div>
                        <div class="col">
                          <label for="add-nom_it" class="form-label"
                            >Difficulté*</label
                          >
                          <small>Note entre 1 et 10 (important)</small>
                          <input
                            type="number"
                            min="1"
                            max="10"
                            class="form-control"
                            :id="'difficulte_' + exercice.id"
                            v-model="exercice.data.difficulte"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <label>Muscles travaillés</label>
                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="exercice.data.muscles"
                              :options="muscles"
                              name="muscles_exercice"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>

                        <div class="col">
                          <label>Materiel</label>

                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="exercice.data.materiels"
                              :options="materiels"
                              name="materiels_exercice"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <p><label>Niveau</label></p>
                          <p><small>Indiqué TOUS les niveaux adaptés</small></p>

                          <b-form-group>
                            <b-form-checkbox-group
                              v-model="exercice.data.niveaux"
                              :options="niveaux"
                              name="niveaux_exercice"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
